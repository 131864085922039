<template>
  <div class="profile">
    <div class="profile__inner">

      <div class="profile__inner--top">
        <div class="profile__head">
          <p class="profile__fio">{{ fio }}</p>
          <div class="profile__add">
            <i
                @click="saveContact"
                class="mdi mdi-account-plus"
            >
            </i>
          </div>
        </div>
        <div class="profile__top">
          <div class="profile__photo profile__gradient">
            <div class="profile__image">
              <img :src="$BACK_URL+'storage/'+photo_path" alt="">
            </div>
          </div>
          <div class="profile__right">
            <p>{{ name_business }}</p>

            <div class="profile__own__info">
              <div class="profile__own__info--line" v-for="(phone,i) in phones" :key="i">
                <a :href="'tel:'+phone.item">
                  <i class="mdi mdi-phone"></i>
                </a>
                <a :href="'tel:'+phone.value">{{ phone.value }}</a>
              </div>
            </div>


          </div>
        </div>
      </div>
      <div class="profile__socials">
        <div class="profile__links">
          <div
              v-for="(link,link_index) in links" :key="link_index"
              @click="openLink(link.value)"
          >
            <div v-if="link.show" class="profile__link">
              <div v-if="link.show">
                <i v-if="link.icon" :class="'mdi '+link.icon"></i>
                <i v-else :class="'mdi '+getIconName(link)"></i>
              </div>
              <a v-if="link.show" class="profile__link--name">{{ link.name }}</a>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      links: [],
      phones: [],
      fio: '',
      name_business: '',
      photo_path: ''

    }
  },
  beforeMount() {
    this.getProfile()
  },
  methods: {
    getIconName(link) {
      if (link.value.includes('instagram')) {
        return 'mdi-instagram'
      }
      if (link.value.includes('wa.me')) {
        return 'mdi-whatsapp'
      }
      if (link.value.includes('2gis')) {
        return 'mdi-map'
      }
      return 'mdi-link-variant';
    },
    saveContact() {
      let contactString = `BEGIN:VCARD\nVERSION:3.0\nFN:${this.fio}\nTEL:${this.phones[0].value}`;

      for (let i = 0; i < this.links.length; i++) {
        const link = this.links[i];

        if (link.icon.includes('instagram')) {
          contactString += `\nX-ABLabel:Instagram\nURL:${link.value}`;
        } else if (link.icon.includes('whatsapp')) {
          contactString += `\nX-ABLabel:Whatsapp\nURL:${link.value}`;
        } else if (link.icon.includes('facebook')) {
          contactString += `\nX-ABLabel:Facebook\nURL:${link.value}`;
        } else if (link.icon.includes('music-box')) {
          contactString += `\nURL:${link.value};TYPE=tiktok;LABEL=TikTok`;
        } else if (link.value.includes('2gis')) {
          contactString += `\nADR:${link.value}`;
        } else if (link.icon.includes('link-variant')) {
          contactString += `\nURL:${link.value}`;
        }

      }

      // if (this.contact.email) {
      //   contactString += `\nEMAIL:${this.contact.email}`;
      // }
      // if (this.contact.address) {
      //   contactString += `\nADR:${this.contact.address}`;
      // }

      let url = this.$BACK_URL + 'storage/' + this.photo_path;
      contactString += `\nX-ABPHOTO:${url}`;
      if (this.name_business) {
        contactString += `\nORG:${this.name_business}`;
      }

      contactString += `\nEND:VCARD`;

      const blob = new Blob([contactString], {type: 'text/vcard'});
      const linkElement = document.createElement('a');
      linkElement.href = window.URL.createObjectURL(blob);
      linkElement.setAttribute('download', 'contact.vcf');
      document.body.appendChild(linkElement);
      linkElement.click();
      document.body.removeChild(linkElement);

    },

    openLink(link) {
      window.open(link, '_blank')
    },
    getProfile() {
      this.$axios({
        method: 'get',
        url: `${this.$BACK_URL}api/profile/by/hash?hash=` + this.$route.params.id,
      })
          .then(response => {
            console.log("ttt", response.data)
            if (response.data.links.length !== 0)
              this.links = response.data.links
            if (response.data.phones.length !== 0)
              this.phones = response.data.phones
            this.fio = response.data.name
            this.name_business = response.data.name_business
            this.photo_path = response.data.photo_path
          })
          .catch(error => {
            console.log(error);
          });
    },
  },
  watch: {},
};
</script>

<style lang="scss">
p, a {
  margin: 0 !important;
  padding: 0 !important;
}

.btn {
  padding: 10px;
  border-radius: 5px;
  color: white;
  background: blue;

}

.profile {
  display: flex;
  flex-direction: column;
  width: 100%;

  .profile__add {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;

    i {
      font-size: 18px;
      color: white;
    }
  }

  .profile__own__info {

    display: flex;
    flex-direction: column;

    i {
      margin-right: 5px;
    }

    .profile__own__info--line {
      display: flex;
      flex-direction: row;
      align-items: center;

      a {
        text-decoration: none;
        color: white;
      }
    }
  }


  .profile__fio {
    font-weight: bold;
    color: white;
    font-size: 30px;
    margin-bottom: 20px;
  }


  .profile__socials {
    .profile__socials--title {
      font-size: 22px;
      margin-bottom: 10px;
      color: #2c2727;
      font-weight: bold;
    }

    .profile__links {
      margin-top: 40px;
      border-radius: 10px;
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      align-self: center;

      .profile__link {
        width: 100px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;

        i {
          color: white;
          font-size: 32px;
        }

        a {
          text-align: center;
        }

        div {
          display: flex;
          justify-content: center;
          align-items: center;
          box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
          background-color: black;
          width: 60px;
          height: 60px;
          border-radius: 20%;
          margin-bottom: 10px;

        }
      }
    }

    .profile__links::-webkit-scrollbar {
      width: 2px; /* Set the width of the scrollbar */
    }

    .profile__links::-webkit-scrollbar-track {
      background-color: transparent; /* Set the background color of the track */
      border-radius: 10px; /* Set border-radius for rounded corners */
    }

    .profile__links::-webkit-scrollbar-thumb {
      background-color: #888; /* Set the color of the scrollbar thumb */
      border-radius: 10px; /* Set border-radius for rounded corners */
    }
  }

  .profile__head {
    margin-bottom: 40px;
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .profile__add {
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }
  }

  .profile__top {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;

    .profile__gradient {
      width: 125px;
      height: 125px;
      border-radius: 50%;
      padding: 6px;
      background: linear-gradient(to right, red, orange);
    }

    .profile__image {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background-color: white;

      img {
        object-fit: cover;
      }
    }

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }

  .profile__right {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      color: white;
      font-size: 26px;
    }
  }
  .profile__right--btns {
    margin-top: 20px;
    display: flex;
    flex-direction: row;

    .profile__save {
      margin-right: 10px;
    }
  }

}

.profile__inner--top {
  padding: 20px;
  background-image: url("../assets/bg_account.jpeg");
  background-size: 100% 50vh;
  background-position: center;
  border-bottom-left-radius: 30%;
  border-bottom-right-radius: 30%;
}
</style>